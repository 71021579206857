import React from 'react'
import { I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import DownloadMain from '../../components/download_main'
import DownloadCard from '../../components/download_card'
import DownloadCardRow from '../../components/download_card_row'

const DownloadAhInstaller = () => {
  const { i18n } = React.useContext(I18nContext)

  return (
      <Layout>
          <SEO title="Arrowhead-Tools" language={i18n.language} />
          <DownloadMain productName="Arrowhead Framework Installer" logoPic="ah-installer-icon">
              <DownloadCard
                  version="1.1.0 Online"
                  compatible="4.5.0+"
                  date="19.06.2023"
                  license="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-installer/downloads/LICENSE.txt"
                  releaseNotes="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-installer/downloads/release-notes-v1.1.0.txt"
              >
                  <DownloadCardRow os="win" fileName="Arrowhead-Framework-Installer-1.1.0.exe" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/win/online/Arrowhead-Framework-Installer-1.1.0.exe" />
                  <DownloadCardRow os="lin" fileName="Arrowhead-Framework-Installer-1.1.0.AppImage" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/linux/online/Arrowhead-Framework-Installer-1.1.0.AppImage" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Framework-Installer-1.1.0-mac.zip" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/mac/online/Arrowhead-Framework-Installer-1.1.0-mac.zip" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Framework-Installer-1.1.0-arm64-mac.zip" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/mac/online/Arrowhead-Framework-Installer-1.1.0-arm64-mac.zip" />
              </DownloadCard>
              <DownloadCard
                  version="1.1.0 Offline"
                  compatible="4.6.2"
                  date="19.06.2023"
                  license="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-installer/downloads/LICENSE.txt"
                  releaseNotes="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-installer/downloads/release-notes-v1.1.0.txt"
              >
                  <DownloadCardRow os="win" fileName="Arrowhead-Framework-Installer-1.1.0.exe" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/win/offline/Arrowhead-Framework-Installer-1.1.0.exe" />
                  <DownloadCardRow os="lin" fileName="Arrowhead-Framework-Installer-1.1.0.AppImage" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/linux/offline/Arrowhead-Framework-Installer-1.1.0.AppImage" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Framework-Installer-1.1.0-mac.zip" path="https://aitia.ai/arrowhead-downloads/arrowhead-framework-installer/mac/offline/Arrowhead-Framework-Installer-1.1.0-mac.zip" />
              </DownloadCard>
          </DownloadMain>
          <DownloadMain productName="Arrowhead Framework Uninstaller" logoPic="ah-installer-icon">
              <DownloadCard
                  version="1.1.0"
                  compatible="4.5.0+"
                  date="19.06.2023"
                  license="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-uninstaller/downloads/LICENSE.txt"
                  releaseNotes="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-uninstaller/downloads/release-notes-v1.1.0.txt"
              >
                  <DownloadCardRow os="win" fileName="Arrowhead-Framework-Uninstaller-1.1.0.exe" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-framework-uninstaller/downloads/win/Arrowhead-Framework-Uninstaller-1.1.0.exe?raw=true" />
                  <DownloadCardRow os="lin" fileName="Arrowhead-Framework-Uninstaller-1.1.0.AppImage" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-framework-uninstaller/downloads/linux/Arrowhead-Framework-Uninstaller-1.1.0.AppImage?raw=true" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Framework-Uninstaller-1.1.0-mac.zip" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-framework-uninstaller/downloads/mac/Arrowhead-Framework-Uninstaller-1.1.0-mac.zip?raw=true" />
                  <DownloadCardRow os="mac" fileName="Arrowhead-Framework-Uninstaller-1.1.0-arm64-mac.zip" path="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/blob/master/arrowhead-framework-uninstaller/downloads/mac/Arrowhead-Framework-Uninstaller-1.1.0-arm64-mac.zip?raw=true" />
              </DownloadCard>
          </DownloadMain>
      </Layout>
  )
}

export default DownloadAhInstaller

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
